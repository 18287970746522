import {
  type FieldMetadata,
  useInputControl,
  unstable_useControl as useControl,
  unstable_Control as Control
} from '@conform-to/react'
import {format, intlFormat} from "date-fns";
import { de, enGB } from 'date-fns/locale';
import {REGEXP_ONLY_DIGITS_AND_CHARS, type OTPInputProps} from 'input-otp'
import {CalendarIcon} from "lucide-react";
import React, {type ComponentProps, type ElementRef, ReactNode, useId, useRef} from 'react'
import {useTranslation} from "react-i18next";
import ReactSelect, {type GroupBase, type OptionsOrGroups} from "react-select"
import {Button} from "#app/components/ui/button.tsx";
import {Calendar} from "#app/components/ui/calendar.tsx";
import {Popover, PopoverContent, PopoverTrigger} from "#app/components/ui/popover.tsx";
import {RadioGroup, RadioGroupItem} from "#app/components/ui/radio-group.tsx";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "#app/components/ui/select.tsx";
import {cn} from "#app/utils/misc.tsx";
import {Checkbox, type CheckboxProps} from './ui/checkbox'
import {Input} from './ui/input'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from './ui/input-otp'
import {Label} from './ui/label'
import {Textarea} from './ui/textarea'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
                            id,
                            errors,
                          }: {
  errors?: ListOfErrors
  id?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  const {t} = useTranslation('common')
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map(e => (
        <li key={e} className="text-[0.8rem] font-medium text-destructive">
          {e?.startsWith('zod.') ? t(e) : e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
                        labelProps,
                        inputProps,
                        errors,
                        hint,
                        className,
                      }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  // @ts-ignore
  const {key, ...inputPropsWithoutKey} = inputProps
  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <Label htmlFor={id} className={cn(errorId && "text-destructive", inputProps.required && "after:content-['*'] after:text-red-500 after:ml-1 after:text-sm")} {...labelProps} />
      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputPropsWithoutKey}
      />
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>
  )
}

export function OTPField({
                           labelProps,
                           inputProps,
                           errors,
                           className,
                         }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: Partial<OTPInputProps & { render: never }>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <InputOTP
        pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
        maxLength={6}
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      >
        <InputOTPGroup>
          <InputOTPSlot index={0}/>
          <InputOTPSlot index={1}/>
          <InputOTPSlot index={2}/>
        </InputOTPGroup>
        <InputOTPSeparator/>
        <InputOTPGroup>
          <InputOTPSlot index={3}/>
          <InputOTPSlot index={4}/>
          <InputOTPSlot index={5}/>
        </InputOTPGroup>
      </InputOTP>
      {errorId && (<div className="px-4 py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : null}
      </div>)}
    </div>
  )
}

export function TextareaField({
                                labelProps,
                                textareaProps,
                                errors,
                                className,
                                hint
                              }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
  errors?: ListOfErrors
  className?: string
  hint?: string | ReactNode
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  // @ts-ignore
  const {key,...inputPropsWithoutKey} = textareaProps
  return (
    <div className={cn("flex flex-col gap-1", className)}>
      <Label htmlFor={id} className={cn(errorId && "text-destructive", textareaProps.required && "after:content-['*'] after:text-red-500 after:ml-1 after:text-sm")} {...labelProps} />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        rows={5}
        {...inputPropsWithoutKey}
      />
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>
  )
}

export function CheckboxField({
                                labelProps,
                                buttonProps,
                                errors,
                                className,
                                hint
                              }: {
  labelProps: JSX.IntrinsicElements['label']
  buttonProps: CheckboxProps & {
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
  hint?: string | ReactNode
}) {
  const {key, defaultChecked, ...checkboxProps} = buttonProps
  const fallbackId = useId()
  const checkedValue = buttonProps.value ?? 'on'
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  })
  const id = buttonProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={state => {
            input.change(state.valueOf() ? checkedValue : '')
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={event => {
            input.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={event => {
            input.blur()
            buttonProps.onBlur?.(event)
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="self-center text-body-xs"
        />
      </div>
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>
  )
}

export function SelectField({
                              labelProps,
                              meta,
                              items,
                              placeholder,
                              errors,
                              hint,
                              className,
                            }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  meta: FieldMetadata<string>;
  items: Array<{ name: string; value: string }>;
  placeholder: string;
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
}) {
  const fallbackId = useId()
  const id = meta.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={cn("flex flex-col gap-1", className)}>
      <Label htmlFor={id} className={cn(errorId && "text-destructive", meta.required && "after:content-['*'] after:text-red-500 after:ml-1 after:text-sm")} {...labelProps} />
      <SelectConform id={id} meta={meta} items={items} placeholder={placeholder}/>
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>
  )
}

export const SelectConform = ({
                                id,
                                meta,
                                items,
                                placeholder,
                                ...props
                              }: {
  id: string;
  meta: FieldMetadata<string>;
  items: Array<{ name: string; value: string }>;
  placeholder: string;
} & ComponentProps<typeof Select>) => {
  const selectRef = useRef<ElementRef<typeof SelectTrigger>>(null);
  const control = useControl(meta);

  return (
    <>
      <select
        id={id}
        name={meta.name}
        defaultValue={meta.initialValue ?? ''}
        className="sr-only"
        ref={control.register}
        aria-hidden
        tabIndex={-1}
        onFocus={() => {
          selectRef.current?.focus();
        }}
      >
        <option value=""/>
        {items.map((option) => (
          <option key={option.value} value={option.value}/>
        ))}
      </select>

      <Select
        {...props}
        value={control.value ?? ''}
        onValueChange={control.change}
        onOpenChange={(open) => {
          if (!open) {
            control.blur();
          }
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder={placeholder}/>
        </SelectTrigger>
        <SelectContent>
          {items.map((item) => {
            return (
              <SelectItem key={item.value} value={item.value}>
                {item.name}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </>
  );
};

export function CheckboxGroupField({

                                     labelProps,
                                     meta,
                                     items,
                                     errors,
                                     hint,
                                     className,
                                   }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  meta: FieldMetadata<string[]>;
  items: Array<{ name: string; value: string }>;
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
}) {
  const fallbackId = useId()
  const id = meta.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <Label className={errorId && "text-destructive"} {...labelProps} />
      <CheckboxGroupConform meta={meta} items={items}/>
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>)
}

export function CheckboxGroupConform({
                                       meta,
                                       items,
                                     }: {
  meta: FieldMetadata<string[]>;
  items: Array<{ name: string; value: string }>;
}) {
  const initialValue =
    typeof meta.initialValue === 'string'
      ? [meta.initialValue]
      : meta.initialValue ?? [];

  return (
    <>
      {items.map((item) => (
        <Control
          key={item.value}
          meta={{
            key: meta.key,
            initialValue: initialValue.find((v) => v == item.value)
              ? [item.value]
              : '',
          }}
          render={(control) => (
            <div
              className="flex items-center gap-2"
              ref={(element) => {
                control.register(element?.querySelector('input'));
              }}
            >
              <Checkbox
                type="button"
                id={`${meta.name}-${item.value}`}
                name={meta.name}
                value={item.value}
                checked={control.value == item.value}
                onCheckedChange={(value) =>
                  control.change(value.valueOf() ? item.value : '')
                }
                onBlur={control.blur}
                className="focus:ring-stone-950 focus:ring-2 focus:ring-offset-2"
              />
              <label htmlFor={`${meta.name}-${item.value}`}>{item.name}</label>
            </div>
          )}
        />
      ))}
    </>
  );
}

export function RadioGroupField({
                                  labelProps,
                                  meta,
                                  items,
                                  errors,
                                  hint,
                                  className,
                                }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  meta: FieldMetadata<string>;
  items: Array<{ value: string; label: string }>;
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
}) {
  const fallbackId = useId()
  const id = meta.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} className={errorId && "text-destructive"} {...labelProps} />
      <RadioGroupConform items={items} meta={meta}/>
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>
  )
}

export function RadioGroupConform({
                                    meta,
                                    items,
                                  }: {
  meta: FieldMetadata<string>;
  items: Array<{ value: string; label: string }>;
}) {
  const radioGroupRef = useRef<ElementRef<typeof RadioGroup>>(null);
  const control = useControl(meta);

  return (
    <>
      <input
        ref={control.register}
        name={meta.name}
        defaultValue={meta.initialValue}
        tabIndex={-1}
        className="sr-only"
        onFocus={() => {
          radioGroupRef.current?.focus();
        }}
      />
      <RadioGroup
        ref={radioGroupRef}
        className="flex items-center gap-4"
        value={control.value ?? ''}
        onValueChange={control.change}
        onBlur={control.blur}
      >
        {items.map((item) => {
          return (
            <div className="flex items-center gap-2" key={item.value}>
              <RadioGroupItem
                value={item.value}
                id={`${meta.id}-${item.value}`}
              />
              <label htmlFor={`${meta.id}-${item.value}`}>{item.label}</label>
            </div>
          );
        })}
      </RadioGroup>
    </>
  );
}

export function MultiSelectField({
                                   labelProps,
                                   meta,
                                   items,
                                   placeholder,
                                   errors,
                                   hint,
                                   className,
                                 }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  meta: FieldMetadata<string[]>;
  items: OptionsOrGroups<any, GroupBase<any>> | undefined;
  placeholder?: string;
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
}) {
  const fallbackId = useId()
  const id = meta.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  const control = useInputControl(meta);

  return (
    <div className={cn("flex flex-col gap-1")}>
      <Label className={cn(errorId && "text-destructive", meta.required && "after:content-['*'] after:text-red-500 after:ml-1 after:text-sm")} {...labelProps} />
      <ReactSelect isMulti options={items}
                   defaultValue={items?.filter(item => control.value?.includes(item.value))}
                   placeholder={placeholder}
                   onChange={(selected) => control.change(selected.map(({value}) => value))}
                   required={meta.required}
                   classNames={{
                     control: (state) => "!border !border-input !rounded-md !min-h-[2.25rem] !text-sm shadow-sm",
                     menuList: (state) => "text-sm",
                   }}
      />
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>)
}

export function SearchableSelectField({
                                        labelProps,
                                        meta,
                                        items,
                                        placeholder,
                                        errors,
                                        hint,
                                        className,
                                      }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  meta: FieldMetadata<string>;
  items: OptionsOrGroups<any, GroupBase<any>> | undefined;
  placeholder?: string;
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
}) {
  const fallbackId = useId()
  const id = meta.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  const control = useInputControl(meta);
  let flatItems = items
  if (items?.[0]?.options) {
    flatItems = items.flatMap(group => group.options)
  }

  return (
    <div className={className}>
      <Label className={cn(errorId && "text-destructive", meta.required && "after:content-['*'] after:text-red-500 after:ml-1 after:text-sm")} {...labelProps} />
      <ReactSelect options={items}
                   defaultValue={flatItems?.filter(item => control.value === item.value)}
                   placeholder={placeholder}
                   onChange={(selected) => control.change(selected?.value)}
                   classNames={{
                     control: (state) => "!border !border-input !rounded-md !min-h-[2.25rem] !text-sm shadow-sm",
                     menuList: (state) => "text-sm",
                   }}
                   instanceId={id}
      />
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>)
}

export function DatePickerField({
                                  labelProps,
                                  meta,
                                  errors,
                                  hint,
                                  className,
                                  disabledDates
                                }: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  meta: FieldMetadata<string>;
  errors?: ListOfErrors
  hint?: string | ReactNode
  className?: string
  disabledDates?: (date: Date) => boolean
}) {
  const {t, i18n} = useTranslation('common')
  const fallbackId = useId()
  const id = meta.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  const control = useInputControl(meta);
  const [date, setDate] = React.useState<Date>(control.value ? new Date(control.value) : new Date())
  const availableLocales: Record<string, any> = {
    'de': de,
    'en': enGB,
  }

  return (
    <div className={className}>
      <Label className={errorId && "text-destructive"} {...labelProps} />
      <div>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-between text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <span>{date ? intlFormat(date, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }, {locale: i18n.language}) : t('common:pickADate')}</span>
              <CalendarIcon className="mr-2 h-4 w-4"/>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={date}
              onSelect={(date) => {
                control.change(format(date as Date, "yyyy-MM-dd"));
                setDate(date as Date);
              }}
              disabled={disabledDates}
              locale={availableLocales[i18n.language]}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
      {(errorId || hint) && (<div className="py-1">
        {errorId ? <ErrorList id={errorId} errors={errors}/> : (hint ?
          <p className="text-[0.8rem] text-muted-foreground">{hint}</p> : null)}
      </div>)}
    </div>)
}